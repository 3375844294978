menu {
  z-index: 15;
  position: absolute;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: #753afb;
  color: #fff;
  overflow: scroll;
  transform: translate3d(0,-100%,0);

  @include make-container();
  @include make-row();
  @include make-col-ready();

  margin: 0;

  &.menu-animated {
    transition: transform 1.5s;
  }

  .collapsing {
    height: auto;
    opacity: 0;
    transition: opacity 0.35s ease;
  }

  img {
    max-width: 100%;

    &.logo {
      max-width: 30%;
      padding: 20px;
    }
  }
}

.mobile-menu {
  margin-top: 80px;
  width: 100%;
  font-size: 14pt;
  line-height: 22.2pt;

  .accordion-body {
    padding-top: 0;
    @include media-breakpoint-up(md) {
      padding-left: 3.5rem;
    }
  }

  .accordion-item {
    background-color: transparent;
    border: none;
    padding: 14px 0;

    h2 {
      display: flex;
      justify-content: center;
    }

    .accordion-button {
      background-color: transparent;
      color: #fff;
      font-size: 20pt;
      letter-spacing: 0.03em;
      width: auto;
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 5px;
    }

    .accordion-collapse {
      padding-top: 20px;
    }

    .accordion-button:focus {
      border: none;
      box-shadow: none;
    }

    .accordion-button::after {
      background-image: none;
      content: " ↓";
      width: auto;
      height: auto;
      margin-left: 10px;
    }

    .accordion-button:not(.collapsed) {
      box-shadow: none;
    }

    .accordion-button:not(.collapsed)::after {
      background-image: none;
      content: " ↓";
    }

    .profession {
      font-size: 80%;
      letter-spacing: 1px;
      padding-right: 20px;
      font-family: 'ABCSocialMono', 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    }
  }

  .ini {
    padding: 20px;
    border: 2px solid white;

    &:not(:first-of-type) {
      border-top-width: 0;
    }
  }
}

@include media-breakpoint-up(md) {
  .mobile-menu {
    @include make-col(4);
    font-size: 16pt;
    line-height: 24.2pt;
    padding-left: 55px;

    .accordion-collapse {
      @include make-col(8);
      position: absolute;
      top: 80px;
      right: 20px;
    }

    .accordion-item {
      .accordion-button {
        font-size: 25pt;
      }

      h2 {
        justify-content: start;
      }

      .accordion-button::after {
        background-image: none;
        content: " \2192";
        width: auto;
        height: auto;
        margin-left: 10px;
      }

      .accordion-button:not(.collapsed)::after {
        background-image: none;
        content: " \2192";
      }
    }
  }
}

table td {
  padding-right: 20px;
}